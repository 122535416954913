:root {
    --color-primaria: #8BC34A;
    --color-secundaria: #6c747e;
    --colors-bounds: rgba(65, 132, 244, 1.000);
    --colors-boundsBg: rgba(65, 132, 244, 0.05);
    --colors-hover: #ececec;
    --colors-overlay: rgba(0, 0, 0, 0.15);
    --colors-overlayContrast: rgba(255, 255, 255, 0.15);
    --colors-panel: #fefefe;
    --colors-panelContrast: #ffffff;
    --colors-selected: rgba(66, 133, 244, 1.000);
    --colors-selectedContrast: #fefefe;
    --colors-text: #333333;
    --colors-tooltip: #1d1d1d;
    --colors-tooltipContrast: #ffffff;
    --colors-warn: rgba(255, 100, 100, 1);
    --colors-canvas: rgb(248, 249, 250);
}

:where(*, *::before, *::after) {
    border-width: 0;
    border-style: solid;
    box-sizing: border-box;
    word-wrap: break-word;
    margin: 0;
    padding: 0;
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

body {
    position: relative;
    color: var(--colors-text);
    background: #f6f6ff;
    transition-property: background-color;
}

header{
    padding: 13px;
    padding: 13px;
    max-width: 1300px;
    margin: 0 auto;
}
.nav{
    display: flex;
}
.nav img{
    max-width: 200px;
}
.espaco{
    flex: 50%;
}
.logo{
    flex: 25%;
}

.botoes-nav{
    flex:25%;
    padding: 23px;
}
.botoes-nav a{
   margin: 15px;
   background-color: var(--color-primaria);
   padding: 8px 22px;
   color: white;
   border-radius: 40px;
   text-decoration: none;
   font-size: 28px;
   box-shadow: 0px 5px 5px #d4d4d5;
   text-shadow: 1px 1px 9px #303030;
}
.botoes-nav a:hover{
    background-color: var(--color-secundaria);

 }
.apresentacao {
    background: linear-gradient(45deg, #8bc34a, #4d8404);
    color: #fff;
    text-align: center;
    min-height: 571px;
    padding: 43px;
    border-radius: 15px;
}
.apresentacao div{
    margin-top: 50px;
    margin-bottom: 40px;
}
.apresentacao h1{
    font-size: 5.5rem;
    margin-bottom: 20px;
    line-height: 86px;
    font-family: "Nunito", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
}
.apresentacao p {
    font-size: 30px;
    color: black;
    margin-top: 40px;
    text-shadow: 3px 5px 7px #5a5c56;
}
section{
    padding: 60px 0;
}

.botao-orcamento {
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    background: linear-gradient(45deg, #25873ad4, #5d8456f7, #67892b);
    font-size: 31px;
    border-radius: 25px;
    text-decoration: none;
    margin: 35px auto;
    max-width: 330px;
    border: solid 3px white;
}
.botao-orcamento:hover {
    background: #333333;
}
.container-grid{
    max-width: 1300px;
    margin: 0 auto;
    padding: 20px 0;
    display: grid;
    gap: 0.375rem;
    width: 100%;
}

.container-flex{
    display: flex;
    max-width: 1300px;
    gap: 0.375rem;
    width: 100%;
}

.apresenta-sub{

    max-width: 1200px;
    margin: 10px auto;
    -webkit-align-items: stretch;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-pack: stretch;
    -ms-flex-pack: stretch;
    -webkit-justify-content: stretch;
    justify-content: stretch;
    min-height: 100%;

}
.apresenta-sub h1{
    font-size: 40px;
    margin: 0;
}
.apresenta{
    margin: 10px;
    border: solid 2px #e4e4e4;
    padding: 25px;
    border-radius: 25px;
    background: linear-gradient(45deg, #6c747ec2, #6c747e);
    box-shadow: 2px 2px 2px #b1b1b1;
    color: white;
}
.apresenta p{
    font-size: 31px;
}
.apresenta h2{
    margin-bottom: 20px;
    font-size: 33px;
}
.servicos{
    margin: 10px auto;
    max-width: 1300px;
}

.servicos h1{
    margin: 10px;
    font-size: 40px;
}

.servicos-conteudo{
    flex: 62.5%;
    width: 100%;
    padding: 10px;
    margin-bottom: 100px;
    margin-top: 100px;
}

.image-lateral{
    flex: 37.5%;
    display: flex;
    align-items: center;
    width: 100%;
    height: auto;
    position: relative;
}

.image-lateral>div{
    position: absolute;
    inset: 0px;
    overflow: hidden;
    background-image: url(https://imgproxy.gamma.app/resize/quality:80/resizing_type:fit/width:1200/https://cdn.gamma.app/vk98fvk8b2mvyn9/generated-images/sjGcpldungoMJIG4EQgth.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 18px;
}


.lista-servicos{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.item-servicos{
    flex: 45%;
    width: 100%;
    margin: 8px;
    border: solid 2px;
    border-radius: 1.25em;
    padding: 14px;
    background-color: white;
}

.item-servicos h2{
    font-size: 1.525em;
    margin-bottom: 15px;
}

.item-servicos p{
    font-size: 22px;
}

/* CONTATO*/
.contato{
    background-color: var(--color-secundaria);
    color: white;
    border-radius: 15px 15px 0 0;
    padding: 43px;
    min-height: 400px;
    
}
.contato h2{
    font-size: 1.725em;
    margin-bottom: 10px;
}

.contato h1{
    margin-bottom: 30px;
    font-size: 40px;
    text-align: center;
}

.contato p{
    font-size: 24px;
    margin-bottom: 10px;
}
.contato-conteudo{
    margin: 0 auto;
    max-width: 1300px;
}
.contato-coluna{
    width: 100%;
    flex: 48%;
    margin: 41px 27px;
}


/* Estilos gerais do formulário */
form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem; /* Espaçamento entre os elementos */
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 100%;
    margin: 0 auto;
  }
  
  /* Estilos para os inputs e textarea */
  input,
  textarea {
    padding: 0.75rem 1rem;
    border: 2px solid #e2e8f0;
    border-radius: 8px;
    font-size: 28px;
    color: #4a5568;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  input:focus,
  textarea:focus {
    border-color: #4f46e5; /* Cor ao focar */
    outline: none;
    box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.2); /* Sombra ao focar */
  }
  
  input::placeholder,
  textarea::placeholder {
    color: #a0aec0; /* Cor do placeholder */
  }
  
  /* Estilos para o botão */
  .contato button {
    padding: 0.75rem 1.5rem;
    background-color: var(--color-primaria);
    color: #ffffff;
    border: none;
    border-radius: 8px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3sease, transform 0.2sease;
    font-size: 28px;
    margin: 0 auto;
  }
  
  .contato button:hover {
    background-color: #4338ca; /* Cor ao passar o mouse */
  }
  
  .contato button:active {
    transform: scale(0.98); /* Efeito de clique */
  }
  

  /* Responsividade */

  @media (max-width: 850px) {
    .apresentacao h1 {
        font-size: 4.5rem;
    }

    .image-lateral {
        height: 600px;
        border-radius: unset;
    }
    .servicos {
        display: grid;
        margin-bottom: 0;
    }

    .servicos-conteudo {
        margin-bottom: 0;
        margin-top: 0;
    }

    section {
        padding: 30px 6px !important;
    }
    .contato-conteudo {
        display: grid;
    }

    .contato {
        padding: 10px;
    }
    .contato-coluna {
        margin: 10px;
        width: unset;
    }
    .botoes-nav{
        display: none;
    }
    .nav {
        display: block;
    }
    .nav img {
        max-width: 170px;
        margin: 0 auto;
        display: block;
    }

  }

  @media (max-width: 480px) {
    form {
      padding: 1.5rem;
    }
    .apresentacao h1 {
        font-size: 2.9rem;
        line-height: 44px;
    }

    input,
    textarea {
      padding: 0.5rem 0.75rem;
      font-size: 0.9rem;
    }
  
    button {
      padding: 0.5rem 1rem;
      font-size: 0.9rem;
    }
    .image-lateral {
        height: 400px;
    }

    .apresentacao div {
        margin-bottom: 10px;
    }

    .apresenta-sub h1  {
        font-size: 32px;
        text-align: center;
    }

    .apresenta{
        margin: 0;  
    }
    .container-flex{
        display: grid;
    }
  }